body {
  width:100%;
  height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.brandText {
  font-weight: bold;
  color: #7ED956;
}
.centreBrandBody {
  height: 100%;
  display: table;
  margin: auto;
}
.rooterBrand {
   display: table-cell;
   vertical-align: middle;
   margin: auto;
}
html {
  height: 100%;
  transition: 0.4s;
}
.fullWidth {
  position:fixed;
  width:50%;
  text-align:center;
  align-self:center;
  overflow:none;
  margin-right:8px;
}
.selectPicker {
 width:100%;
 outline:none;
 border:none;
 border-color:black;
 font-size:16px;
 border-style:solid;
 margin-left:8px;
 margin-right:8px;
}
